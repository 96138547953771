$fontSizes: (
  'nano': 9px,
  'micro': 10px,
  'tiny': 11px,
  'little': 12px,
  'xSmall': 13px,
  'small': 14px,
  'compact': 15px,
  'medium': 16px,
  'large': 18px,
  'great': 22px,
  'xLarge': 24px,
  'xxLarge': 40px,
  'huge': 50px,
  'xHuge': 60px,
  'xxHuge': 80px,
);

$fontWeights: (
  'light': 300,
  'regular': 400,
  'medium': 500,
  'bold': 700,
  'extraBold': 800,
  'black': 900,
);

@function size($size) {
  @return map-get($fontSizes, $size);
}

@function weight($weight) {
  @return map-get($fontWeights, $weight);
}
