@use 'theme';
@use 'fonts';
@use 'breakpoints';

.wrapper {
  display: flex;
  align-items: var(--mobileAlign);
  flex-direction: column;

  @include breakpoints.up('small') {
    align-items: var(--align);
  }
}

.linearWrapper {
  display: flex;
  opacity: 0.6;
}

.netPrices {
  align-items: center;
  display: flex;
  margin-bottom: theme.spacing(0.25);
  opacity: 0.9;
}

.grossPrices {
  display: flex;
  align-items: baseline;
}

.oldGrossPrice {
  color: rgba(theme.get('textColorRgb'), 0.6);
  font-size: fonts.size('little');
  margin-left: theme.spacing(1);
  text-decoration: line-through;
}

.extendedPrice {
  margin-top: theme.spacing(0.25);
  color: theme.get('successColor');
}

.slash {
  margin: theme.spacing(0, 0.5);
  line-height: 16px;
}
