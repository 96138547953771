@use 'theme';
@use 'fonts';

.wrapper {
  display: flex;
  position: relative;
  align-items: center;
  height: var(--starSize);
}

.starsRating {
  position: relative;

  & .star {
    fill: theme.get('secondaryBackgroundColor');
  }
}

.numberRating {
  color: theme.get('textColor');
  font-size: fonts.size('tiny');
  margin-left: theme.spacing(0.75);
}

.activeStars {
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: var(--ratingInPercent);

  & .star {
    fill: theme.palette('gold');
  }
}

.stars {
  display: flex;
  width: var(--starsWidth);
}

.star {
  height: 100%;
  margin: var(--starMargin);
  width: 100%;
  min-width: 0;
}

.cursorPointer {
  cursor: pointer;
}