@use 'theme';
@use 'fonts';
@use 'breakpoints';
@use 'display';
@use 'borders';
@use 'transitions';

.headerLeftWrapper {
  display: flex;
  justify-content: flex-end;

  & > .header {
    border-radius: borders.radius('lg', 0, 0, 'lg');
  }
}

.header {
  align-items: center;

  @include breakpoints.up('small') {
    padding: theme.spacing(0, 5.75);
    text-transform: uppercase;
    background-color: theme.get('standardProductBackground');
  }
}

.desktopHeader {
  display: none;
  height: 72px;
  border-radius: borders.radius(0, 'lg', 'lg', 0);

  @include breakpoints.up('large') {
    display: inline-flex;
  }
}

.mobileHeader {
  height: 58px;
  border-radius: borders.radius('lg');
  display: flex;
  margin-bottom: theme.spacing(1);

  @include breakpoints.up('small') {
    justify-content: center;
  }

  @include breakpoints.up('large') {
    display: none;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  margin: theme.spacing(0, 2);

  @include breakpoints.up('small') {
    display: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: theme.spacing(1.5);

  @include breakpoints.up('large') {
    &.left {
      flex-direction: row-reverse;
    }
    &.right {
      flex-direction: row;
    }
  }
}

.items {
  display: flex;
  flex-direction: column;
  row-gap: theme.spacing(1);
  width: 100%;

  @include breakpoints.up('small') {
    flex-direction: row;
  }

  @include breakpoints.up('large') {
    width: 843px;
  }

  @include breakpoints.up('xLarge') {
    width: 891px;
  }
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 108px;
  width: 100%;
  background-color: rgba(theme.get('activeColorRgb'), var(--alpha));
  margin-right: theme.spacing(0.125);
  padding-top: theme.spacing(2.75);
  border-radius: borders.radius('lg');

  @include breakpoints.up('small') {
    height: 464px;
    width: var(--boxWidth);
    background-color: theme.get('standardProductBackground');
    border-radius: 0;

    &.left {
      &:first-of-type {
        border-radius: borders.radius('lg', 0, 0, 'lg');
        & .button:last-of-type {
          border-bottom-left-radius: borders.radius('lg');
        }

        @include breakpoints.up('large') {
          border-radius: borders.radius(0, 0, 0, 'lg');
        }
      }
      &:last-of-type {
        border-radius: borders.radius(0, 'lg', 'lg', 0);
        margin-right: theme.spacing(0);
        & .button:last-of-type {
          border-bottom-right-radius: borders.radius('lg');
        }
      }
    }

    &.right {
      &:first-of-type {
        border-radius: borders.radius('lg', 0, 0, 'lg');
        & .button:last-of-type {
          border-bottom-left-radius: borders.radius('lg');
        }
      }
      &:last-of-type {
        border-radius: borders.radius(0, 'lg', 'lg', 0);
        margin-right: theme.spacing(0);
        & .button:last-of-type {
          border-bottom-right-radius: borders.radius('lg');
        }

        @include breakpoints.up('large') {
          border-radius: borders.radius(0, 0, 'lg', 0);
        }
      }
    }
  }

  @include breakpoints.up('medium') {
    height: 436px;
  }
}

.bigBox {
  display: flex;
  width: 100%;
  border-radius: borders.radius('lg');

  @include breakpoints.up('small') {
    height: 436px;
    padding-top: theme.spacing(8.625);
    background-color: theme.get('standardProductBackground');
  }

  @include breakpoints.up('large') {
    width: 888px;
    &.left {
      border-radius: borders.radius(0, 'lg', 'lg', 'lg');
    }
    &.right {
      border-radius: borders.radius('lg', 0, 'lg', 'lg');
    }
  }
}

.bigImageWrapper {
  display: none;

  @include breakpoints.up('small') {
    display: flex;
    min-width: 56%;
    justify-content: center;
  }
}

.bigBoxHeader {
  display: block;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: theme.spacing(2);
}

.bigBoxContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @include breakpoints.up('small') {
    width: auto;
  }
}

.bigBoxTexts {
  display: none;

  @include breakpoints.up('small') {
    display: block;
    padding-right: theme.spacing(3);
  }
}

.bigBoxItems {
  display: flex;
  flex-direction: column;
  row-gap: theme.spacing(1);

  @include breakpoints.up('small') {
    display: grid;
    grid-auto-flow: column dense;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    row-gap: theme.spacing(0);
    column-gap: theme.spacing(0.125);
  }
}

.texts {
  display: none;

  @include breakpoints.up('small') {
    display: block;
  }

  padding: theme.spacing(0, 3);
}

.subName {
  font-size: theme.get('menuTileFontSize');
  margin: theme.spacing(theme.get('menuTileMarginTop'), 0, 1);
}

.imageNameWrapper {
  & picture {
    display: flex;
    justify-content: center;

    @include breakpoints.up('medium') {
      display: block;
    }
  }
}

.imageName {
  @include breakpoints.up('medium') {
    padding: theme.spacing(0, 3);
  }
}

.button {
  display: inline-flex;
  height: 48px;
  padding: theme.spacing(0, 2);
  color: rgba(theme.get('textColorRgb'), 0.9);

  &:hover .seriesName {
    margin-left: theme.spacing(1);
    font-weight: fonts.weight('bold');
    color: theme.get('textColor');
  }

  @include breakpoints.up('small') {
    width: 100%;
    padding: theme.spacing(0, 2.625, 0, 4);
  }
}

.buttonColumn {
  @include breakpoints.up('small') {
    background-color: rgba(theme.get('activeColorRgb'), var(--alpha));
  }
}

.buttonGrid {
  background-color: rgba(theme.get('activeColorRgb'), var(--alpha));
  border-radius: borders.radius('lg');
  height: 66px;
  justify-content: center;

  @include breakpoints.up('small') {
    height: 48px;
    justify-content: flex-start;
    border-radius: 0;
    &:first-of-type {
      border-radius: borders.radius('lg', 0, 0, 0);
    }
    &:last-of-type {
      border-radius: borders.radius(0, 0, 'lg', 0);
    }
  }
}

.series {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;

  & > a:first-child {
    margin-left: auto;
  }

  & > a:last-child {
    margin-right: auto;
  }

  @include breakpoints.up('small') {
    display: block;
    white-space: normal;
    overflow-x: visible;
  }
}

.seriesName {
  display: flex;
  align-items: center;
  transition: transitions.get('margin-left');
}

.arrow {
  width: 3.5px;
  margin-left: theme.spacing(1.5);
}

.squareHelper {
  display: none;
  position: absolute;
  width: 20px;
  height: 20px;
  background: theme.get('standardProductBackground');
  top: 72px;

  &.left {
    right: 0;
  }

  @include breakpoints.up('large') {
    display: block;
  }
}

.product {
  width: 100%;
  position: relative;

  @include breakpoints.up('small') {
    height: 375px;
  }

  @include breakpoints.up('medium') {
    height: 242px;
  }

  @include breakpoints.up('large') {
    width: 453px;
    height: 432px;
  }
}

.productWrapper {
  padding: theme.spacing(1, 0, 0, 0);
  background-color: theme.palette('white');
  border-radius: borders.radius('lg');

  @include breakpoints.up('small') {
    position: absolute;
  }

  @include breakpoints.up('large') {
    height: 364px;
    padding: theme.spacing(1, 0, 0, 1);
    &.left {
      padding: theme.spacing(1, 1, 0, 0);
    }
  }
}

.mobile {
  @include display.hideOnDesktop;
}

.desktop {
  @include display.hideOnMobile;
}
