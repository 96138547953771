@use 'theme';
@use 'fonts';
@use 'borders';
@use 'breakpoints';
@use 'ripple';
@use 'shadows';
@use 'transitions';
@use 'typography';

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: borders.radius('lg');
  transition: transitions.get('box-shadow');
  position: relative;
  background-color: theme.get('backgroundColor');
  padding: theme.spacing(3, 2);

  &:hover {
    box-shadow: theme.get('tileBoxShadow');
  }

  @include breakpoints.up('medium') {
    flex-direction: row;
  }

  @include breakpoints.up('large') {
    flex-direction: column;
    padding: theme.spacing(3);
  }

  @include breakpoints.up('xLarge') {
    padding: theme.spacing(4.5, 3.75, 3.75);
  }
}

.promoted {
  display: flex;
  position: absolute;
  z-index: 1;
  background-color: theme.get('activeColor');
  top: 23px;
  height: 30px;
  width: 150px;
  font-size: fonts.size('compact');
  color: theme.palette('white');
  align-items: center;
  justify-content: center;
  box-shadow: theme.get('tileBoxShadow');

  &.right {
    right: -38px;
    transform: rotate(45deg);

    @include breakpoints.up('small') {
      left: -38px;
      right: auto;
      transform: rotate(-45deg);
    }

    @include breakpoints.up('large') {
      right: -38px;
      left: auto;
      transform: rotate(45deg);
    }
  }

  &.left {
    right: -38px;
    transform: rotate(45deg);

    @include breakpoints.up('small') {
      left: -38px;
      transform: rotate(-45deg);
    }
  }
}

.content {
  display: flex;
  align-items: center;
  margin-bottom: theme.spacing(2);
  gap: theme.spacing(1);
  width: 100%;
  justify-content: space-between;

  @include breakpoints.up('medium') {
    width: 50%;
    justify-content: flex-end;
  }

  @include breakpoints.up('large') {
    width: 100%;
    justify-content: space-between;
  }

  &.left {
    @include breakpoints.up('small') {
      flex-direction: row-reverse;
    }
  }

  &.right {
    @include breakpoints.up('small') {
      flex-direction: row-reverse;
    }

    @include breakpoints.up('large') {
      flex-direction: row;
    }
  }
}

.rightContent {
  @include breakpoints.up('medium') {
    width: 50%;
  }

  @include breakpoints.up('large') {
    width: auto;
  }
}

.column {
  display: flex;
  flex-direction: column;
  width: calc(50% - theme.spacing(1));
}

.starRating {
  margin-top: theme.spacing(0.75);
  margin-bottom: theme.spacing(2.25);
}

.attributes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(var(--attributesRows), auto);
  grid-column-gap: theme.spacing(1);
  grid-auto-flow: column dense;
  padding-bottom: theme.spacing(2);
  border-bottom: borders.get();
  margin-bottom: theme.spacing(2);
}

.attribute {
  @include breakpoints.up('medium') {
    @include typography.get('body2');
    line-height: 18px;
  }
}

.imageWrapper {
  width: 50%;

  & picture {
    display: flex;
    justify-content: center;
    max-width: 155px;

    @include breakpoints.up('small') {
      max-width: 164px;
    }
  }

  & img {
    height: auto;
    width: auto;
    max-width: 155px;
    max-height: 100px;

    @include breakpoints.up('small') {
      max-width: 164px;
      max-height: 129px;
    }
  }

}

.name {
  white-space: nowrap;
  @include breakpoints.up('small') {
    @include typography.get('h2');
  }
}
