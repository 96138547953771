@use 'fonts';

.wrapper {
  font-weight: fonts.weight('regular');

  &.isBolded {
    font-weight: fonts.weight('bold');
  }
}

.isFaded {
  opacity: 0.8;
}

@each $variant, $value in fonts.$fontSizes {
  .#{$variant} {
    font-size: $value;
  }
}