@use 'theme';
@use 'borders';

.container {
  display: flex;
  align-items: center;
  height: 37.56px;
  margin-top: theme.spacing(0.75);
}

.button {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: borders.radius('md');
  border: borders.get($color: theme.get('successColor'));
  padding: theme.spacing(0.3, 0.875);
  gap: theme.spacing(0, 0.25);
  max-width: 176px;
  color: theme.get('successColor');

  &.tommorow {
    max-width: 159px;
  }
}